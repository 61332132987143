import React from "react";
import { Heading } from "./common/Heading";
import { about } from "./data/dummydata";

import './assets/aboutComponent.css';

export const About = () => {    
  return (
    <section className='about container'>
      <Heading title='Notre entreprise' />

      <div className="about-container-grid">
        {about[0].content}
        <div class="about-image">
          <img
            src={about[1].cover}
            className="img-fluid beige-shadow"
            id="about-second-cover"
            alt='Mountain Solar Panels and Descriptive Text'
            data-aos='fade-down-right'
          />
        </div>
      </div>
      {about[1].content}
    </section>

//     <section class="about container">
//     <h1 class="heading" data-aos="zoom-in-down">Notre entreprise</h1>
//     <div class="about-container-grid">
//         <div class="about-content">
//             <p>Bienvenue chez Sun Alternative Invest, votre partenaire dédié à la transition énergétique. Nous souhaitons jouer un rôle crucial dans le <b>secteur de l'énergie solaire</b>, en aidant les entreprises à faire la transition vers une énergie plus durable.</p>
//             <p>Notre objectif est de contribuer de manière significative à la Responsabilité Sociétale des Entreprises (RSE) et à <b>l’indépendance énergétique</b> en offrant des <b>solutions clés-en-main</b>, de la consultation initiale à l'installation et à l'entretien continu des installations photovoltaïques. Cette approche globale garantit une transition fluide vers l'énergie solaire pour nos clients.</p>
//         </div>
//         <div class="about-image">
//             <img src="./images/home/2.png" class="img-fluid beige-shadow" alt="Mountain Solar Panels and Descriptive Text">
//         </div>
//     </div>
//     <div class="about-extra-content">
//         <h3>Nous nous engageons à guider les entreprises pour :</h3>
//         <ul class="sub-content-list">
//             <li><strong>Une autonomie Énergétique</strong> : Nous visons à rendre les entreprises autonomes sur le plan énergétique en exploitant pleinement le potentiel de l'énergie solaire. Cela permettra aux entreprises de réduire leur dépendance aux sources d'énergie traditionnelles et de réaliser des économies à long terme.</li>
//             <li><strong>Une Réduction de l'empreinte carbone</strong> : En favorisant l'utilisation de l'énergie solaire, nous contribuons activement à la réduction des émissions de gaz à effet de serre. Nous sommes déterminés à soutenir les entreprises dans leur démarche de réduction de leur empreinte carbone, renforçant ainsi leur engagement envers le développement durable.</li>
//         </ul>
//         <p>Nous sommes fiers de contribuer activement à la transition énergétique de notre région, offrant des solutions durables et innovantes pour répondre aux défis environnementaux tout en stimulant la croissance économique locale.</p>
//         <div class="about-button" data-aos="fade-right">
//             <a class="black about-button-link" href="/about">
//                 <button class="button-secondary">En savoir plus</button>
//             </a>
//         </div>
//     </div>
// </section>
  );
};
