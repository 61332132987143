import React from "react"
import { About } from "../About"
import { Services } from "../Services"
import { Contact } from "../pages/Contact"
import { Hero } from "./Hero"
import { Advantages } from "../Advantages"
import ScrollToTop from "../common/ScrollToTop"

export const Home = () => {
  return (
    <>    
      <Hero />
      <About />
      <hr className="hero-line"></hr>
      <Advantages />
      <Services />
      <hr className="hero-line"></hr>
      <Contact />
    </>
  )
}
