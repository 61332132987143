import React, { useEffect, useState } from "react";
import '../assets/blogDetailsPage.css';
import { Heading } from "../common/Heading";
import { blogs } from "../data/dummydata";
import AOS from 'aos';
import 'aos/dist/aos.css';

const BlogDetails = ({ match }) => {
    const [blog, setBlog] = useState({});

    useEffect(() => {
        AOS.init();
        getBlog(match.params.id);
    }, [match.params.id]);

    const getBlog = (blogId) => {
        const selectedBlog = blogs.find(blog => blog.id === parseInt(blogId));
        setBlog(selectedBlog);
    }

    return (
        <section className='container' id="blogDetails">
            <Heading title={blog.title} />
            <div className='wrapper-blog'>
                <div data-aos="fade-up">
                    <span>Date de publication: {blog.date} - Auteur: {blog.author}</span>
                    <p>{blog.desc}</p>
                </div>

                <div className="blog-content" data-aos="fade-up">
                    {blog.content}
                </div>
            </div>
        </section>
    );
};

export default BlogDetails;
