import { Menu as MenuIcon, Cancel as CroixIcon } from "@mui/icons-material";
import React, { useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { navlink, blogSubLinks } from "../data/dummydata"
import logo from "../data/images/logo.png"

import '../assets/headerComponent.css';

export const Header = () => {
  const location = useLocation();
  const [responsive, setResponsive] = useState(false);
  const [showBlogDropdown, setShowBlogDropdown] = useState(false);


  const isHomePage = location.pathname === '/';
  const headerStyle = !isHomePage
    ? { position: 'relative' }
    : { position: 'absolute' };

  const navStyle = !isHomePage
    ? { position: 'relative' } : { position: responsive 
    ? 'absolute' : 'sticky' };

  const closeMenu = () => {
      setResponsive(false);
      setShowBlogDropdown(false);
  };

  const toggleBlogDropdown = () => {
    setShowBlogDropdown(!showBlogDropdown);
  };


  return (
    <header style={headerStyle} className={isHomePage ? 'home-nav' : ''}>
        <div className='logo'>
          <Link to={'/'} aria-label="Home" rel="preload">
            <img src={logo} alt='Logo Sun Alternative Invest' data-aos='zoom-in-right' className="logo_img"  />
          </Link>
        </div>
        <div className={responsive ? "hideMenu" : "nav"} style={navStyle} aria-label="Main Navigation">
          {navlink.map((links, index) => (
            <Link  
              to={links.url} 
              key={index} 
              data-aos='zoom-in-left'
              className={links.url === location.pathname ? 'active' : ''}
              onClick={closeMenu}
              onMouseEnter={() => links.url === '/blogs' && toggleBlogDropdown()}
              onMouseLeave={() => links.url === '/blogs' && setShowBlogDropdown(false)}              
            >
              {links.text}
            </Link>
          ))}
        </div>

        {/* {showBlogDropdown && (
          <div className="blog-dropdown">
            {blogSubLinks.map((blog, index) => (
              <Link
                to={`/blogs/${blog.url}`}
                key={index}
                data-aos="zoom-in-left"
                className={location.pathname.includes(`/blogs/${blog.url}`) ? 'active' : ''}
                onClick={closeMenu}
              >
                {blog.text}
              </Link>
            ))}
          </div>
        )} */}

        <button className={responsive ? 'toggle-opened': "toggle"} onClick={() => setResponsive(!responsive)} aria-label={responsive ? "Close Menu" : "Open Menu"}>
          {responsive ? <CroixIcon className='icon' /> : <MenuIcon className='icon' />}
        </button>
      </header>
  )
}
