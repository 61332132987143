// MentionsLegales.js
import React from 'react';
import { Heading } from '../common/Heading';
import '../assets/mentionsLegales.css';

const MentionsLegales = () => {
  return (
    <section id="mentionsLegales">
      <div className="container">
        <Heading title="Mentions Légales"></Heading>
        <div className='wrapper'>
        <h2>1. Propriétaire du site</h2>
        <ul>
            <li>
                <a href="https://www.sunaltinvest.com">Sun Alternative Invest</a><br/>
                <a href="tel:+33 6 66 23 45 26">Téléphone: +33 6 66 23 45 26</a> 
            </li>
        </ul>
        </div>
        <p className='hero-line'></p>

        <div className='wrapper'>
            <h2>2. Directeurs de la publication"</h2>
            <p>
                Fanny Margeolet, Présidente
                <ul>
                    <li><a href="mailto:Fanny.margeolet@sunaltinvest.com">Email: Fanny.margeolet@sunaltinvest.com</a></li>
                    <li><a href="tel:+ 33 6 66 23 45 26">Téléphone: + 33 6 66 23 45 26</a></li>
                </ul>
            </p>
            <p>
                Alexandre Avian, Directeur Général
                <ul>
                    <li><a href="mailto:alexandre.avian@sunaltinvest.com">Email: Alexandre.avian@sunaltinvest.com</a></li>
                    <li><a href="tel:+33 6 18 46 18 96">Téléphone: +33 6 18 46 18 96</a></li>
                </ul>
            </p>
        </div>
        <p className='hero-line'></p>

        <div className='wrapper'>
            <h2>3. Hébergement du site</h2>
            <p>Le site est hébergé par GoDaddy</p>
            <p><a href="https://www.godaddy.com/fr-fr" target='_blank' rel="noreferrer">Adresse de l'hébergeur</a></p>
        </div>
        
      </div>
    </section>
  );
};

export default MentionsLegales;
