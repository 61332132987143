import React, { useState, useEffect } from 'react';
import { Fab, useScrollTrigger, Zoom } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const ScrollToTop = () => {
  const trigger = useScrollTrigger({ target: window });

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className='scrollTop'>
    <Zoom in={trigger}>
      <Fab 
        onClick={handleClick} 
        size="small" 
        aria-label="scroll back to top"
        style={{ background: 'var(--secondaryColor)', color: 'white' }}    
    >
        <KeyboardArrowUpIcon />
      </Fab>
    </Zoom>
    </div>
  );
};

export default ScrollToTop;